exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-message-tsx": () => import("./../../../src/pages/message.tsx" /* webpackChunkName: "component---src-pages-message-tsx" */),
  "component---src-pages-news-microcms-news-news-id-tsx": () => import("./../../../src/pages/news/{microcmsNews.newsId}.tsx" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-tsx" */),
  "component---src-pages-news-news-tsx": () => import("./../../../src/pages/news/news.tsx" /* webpackChunkName: "component---src-pages-news-news-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-sent-tsx": () => import("./../../../src/pages/sent.tsx" /* webpackChunkName: "component---src-pages-sent-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */)
}

